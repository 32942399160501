import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { Link } from "gatsby"

function Page({ pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title, 
    description: page.frontmatter.description
  }
  return (
    <Layout meta={meta}>
      <header id="service">Our Services</header>
      <div class="intro">
        <blockquote>Let us therefore make every effort to do what leads to peace and to mutual edification.<cite>–Romans 14:19</cite></blockquote>
        <p>If your dispute or conflict is more than you can handle on your own, consider our comprehensive dispute resolution and conflict reconciliation services. PeacePoint provides coaching, mediation, arbitration, and consulting services to individuals, organizations, churches, ministries, and denominations. Browse the topics below to learn more about our services.</p>
      </div>
      <div className="tabs-list">
        <Link
          to="/coaching" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Coaching</Link>
        <Link
          to="/mediation" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Mediation</Link>
        <Link
          to="/arbitration" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Arbitration</Link>
        <Link
          to="/consultation-intervention" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Consultation &amp; Intervention</Link>
        <Link
          to="/help-with-marriage-conflict" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Help With Marriage Conflict</Link>
        <Link
          to="/costs" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >What Will It Cost</Link>
        <Link
          to="/get-started" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >How To Get Started</Link>
        <Link
          to="/grievance-policy" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Grievance Policy &amp; Procedures</Link>
        <Link
          to="/forms-rules-procedures" activeStyle={{ color: "#f4f4f2", backgroundColor: "#501486" }}
                    >Forms/Rules/Procedures</Link>
      </div>
      <hr className="divider" />
      <div className="content">
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
    </Layout>
  );
}

export default Page;
